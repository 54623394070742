 import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrordialogService } from './error-dialog/errordialog.service';
import { ErrordialogComponent } from './error-dialog/errordialog/errordialog.component';
import { LoginComponent } from './components/login/login.component';
import { OtpComponent } from './components/otp/otp.component';
import { CreditComponent } from './components/credit/credit.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { TransactionDetailsComponent } from './components/transaction-details/transaction-details.component';
import { CongratulationComponent } from './components/congratulation/congratulation.component';
import { AvailableLimitComponent } from './components/available-limit/available-limit.component';
import { SorryComponent } from './components/sorry/sorry.component';
import { DuePaymentComponent } from './components/due-payment/due-payment.component';
import { PayAmountComponent } from './components/pay-amount/pay-amount.component';
import { CongoComponent } from './components/congo/congo.component';

import { OtppopupComponent } from './components/otppopup/otppopup.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

import { HttpConfigInterceptor } from './httpconfig.interceptor';

import { NgOtpInputModule } from 'ng-otp-input';

import { PipesModule } from './pipes/pipes.module';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SpinnerService } from './services/spinner.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { ProcessingfeeComponent } from './components/processingfee/processingfee.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OtpComponent,
    CreditComponent,
    WithdrawComponent,
    TransactionHistoryComponent,
    TransactionDetailsComponent,
    CongratulationComponent,
    AvailableLimitComponent,
    SorryComponent,
    DuePaymentComponent,
    PayAmountComponent,
    CongoComponent,
    ErrordialogComponent,
    OtppopupComponent,
    SpinnerComponent,
    PagenotfoundComponent,
    ProcessingfeeComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatSliderModule,
    MatCardModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatBottomSheetModule,
    NgOtpInputModule,
    PipesModule,
    NgxSliderModule
  ],
  providers: [ 
    ErrordialogService, 
    SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
   ],
   entryComponents: [ ErrordialogComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }
