<!--<div class="container-logo-1" *ngIf="auth.isAuthenticated(); else elseblock">
    <div class="header-2">

        <div class="">
            <div >
                <img src="assets/logo.png" alt="" class="logo-img" />
            </div>

            <div style="position: relative;top: -2.5rem; right: 1.5rem;">
                <div class="d-flex flex-row-reverse">
               <mat-icon aria-label="Logout" (click)="logoutUser()" class="m-2 mat-icon material-icons" role="img" aria-hidden="true"  style="cursor: pointer;">power_settings_new</mat-icon>
                </div>
            </div>

        </div>

       
        
    </div>
</div>
<ng-template #elseblock>-->

    <div  [ngClass]="[auth.isAuthenticated() ? 'container-logo-1' : 'container-logo']">
        <div [ngClass]="[auth.isAuthenticated() ? 'header-2' : 'header']">
            <img src="assets/logo.png" alt="" height="45px" width="30px" />
    
            <h1 *ngIf="!auth.isAuthenticated();" class="mtt-3">Welcome!</h1>

            <div *ngIf="auth.isAuthenticated();" style="position: relative;top: -2.5rem; right: 1.5rem;">
                <div class="d-flex flex-row-reverse">
               <mat-icon aria-label="Logout" (click)="logoutUser()" class="m-2 mat-icon material-icons" role="img" aria-hidden="true"  style="cursor: pointer;">power_settings_new</mat-icon>
                </div>
            </div>
        </div>
    </div>
<!--</ng-template>-->



<router-outlet></router-outlet>
<app-spinner> </app-spinner>
