import { Component, OnInit, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { OtppopupComponent } from '../otppopup/otppopup.component';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css']
})
export class WithdrawComponent implements OnInit, AfterViewInit {
  autoTicks = false;
  disabled = false;
  invert = false;
  max = 0;
  min = 1000;
  showTicks = true;
  step = 1000;
  thumbLabel = false;
  value = 0;
  vertical = false;
  tickInterval = 1;

  WithdrawForm: FormGroup;

  customer_code: string;
  mobile: string;

  loanDetails: any;
  avilableLimit: number;
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  valid_date: string;
  loan_id: string;

  slider_value: number = 0;
  steps: number = 1000;


  options: Options = {
    floor: 0,
    ceil: 0,
    step: this.steps,
    showSelectionBar: true,

  };

  params = {
    defaultValue: 0,
    min: this.min,
    max: this.max,
    step: this.steps
  };
  inputValue: number = this.params.defaultValue;
  interest_amount: number = 0;

  set_span_val: number = 0;
  stepsValidator(control: AbstractControl) {
    if (!control.value || control.value % this.steps !== 0) {
      return { error: true };
    }
    return null;
  }
  handleInputChange(e) {
    let target = e.target
    if (e.target.type !== 'range') {
      target = document.getElementById('range')
    }
    const min = target.min
    const max = target.max
    const val = target.value
    target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
  }

  constructor(private elementRef: ElementRef, private bottomSheet: MatBottomSheet, private apiService: ApiService, private fb: FormBuilder, private router: Router, private cdRef: ChangeDetectorRef) { }
  sliderControl: FormControl = new FormControl(this.slider_value, [Validators.required, Validators.min(1000)]);

  ngAfterViewInit(): void {
    const rangeInputs = document.querySelectorAll('input[type="range"]');
    const numberInput = document.querySelector('input[type="number"]');


    let el = this.elementRef.nativeElement.querySelector(".input-wrap .input-amount");
    let widthMachine = this.elementRef.nativeElement.querySelector(".input-wrap .width-machine");

    numberInput.addEventListener('input', this.handleInputChange);

    rangeInputs.forEach(input => {
      input.addEventListener('input', this.handleInputChange);
    })


    el.addEventListener("keyup", () => {
      el.innerHTML = el.value;
    });
    //const numberInput = this.elementRef.nativeElement.querySelector('input[type="number"]');
    //numberInput.addEventListener('input', this.handleInputChange);

  }
  ngOnInit(): void {



    this.WithdrawForm = this.fb.group({
      amount: this.sliderControl,
      text_amount: [this.slider_value]
      //text_amount: new FormControl(this.slider_value, this.stepsValidator.bind(this))
    })

    this.apiService.fetchLoanDetails().subscribe((data: any) => {

      if (data.code == "OK") {

        this.loanDetails = data.loan;

        this.loan_id = this.loanDetails.loan_uid;
        //this.avilableLimit = (this.loanDetails.amount_offered - this.loanDetails.amount_outstanding);

        this.avilableLimit = this.loanDetails.principal_repaid;

        this.max = this.avilableLimit;
        const newOptions: Options = Object.assign({}, this.options);
        newOptions.ceil = this.avilableLimit;
        this.options = newOptions;

        this.params.max = this.avilableLimit;

        let interest_rate = Number((parseFloat(this.loanDetails.interest_rate_percent) * 30).toFixed(1));

        //this.interest_amount =  this.avilableLimit * parseFloat(this.loanDetails.interest_rate_percent)/100 || 0;

        this.interest_amount = (interest_rate * this.avilableLimit) / 100 || 0;
        this.interest_amount = Number(this.interest_amount.toFixed(2))
        let limit_till = new Date(this.loanDetails.limit_validity);
        if (this.loanDetails.limit_validity != "") {
          this.valid_date = this.monthNames[limit_till.getMonth()] + ' ' + limit_till.getDate() + ', ' + limit_till.getFullYear();
        }


      }
    });


    this.WithdrawForm.get("amount").valueChanges.subscribe(x => {
      this.set_span_val = x;
      this.WithdrawForm.get("text_amount").setValue(x, { emitEvent: false });
    })

    this.WithdrawForm.get("text_amount").valueChanges.subscribe(y => {

      if (y) {
        this.set_span_val = y;
        this.params.defaultValue = y;
        this.WithdrawForm.get("amount").setValue(y, { emitEvent: false });

        /*if (y % this.steps!= 0)
        {
          let checkval = y/this.steps
          let nearestval = parseInt(''+ checkval)*this.steps;
  
          console.log(nearestval)
          this.WithdrawForm.get("text_amount").setValue(nearestval, {emitEvent: false});
  
          this.params.defaultValue=nearestval;
          this.WithdrawForm.get("amount").setValue(nearestval, {emitEvent: false});
        }*/

      }

      //this.sliderControl.reset(y);

      if (y > this.avilableLimit) {
        this.set_span_val = this.avilableLimit;
        this.params.defaultValue = this.avilableLimit;
        this.WithdrawForm.get("text_amount").setValue(this.avilableLimit, { emitEvent: false });
        //this.sliderControl.reset(this.avilableLimit);
        this.WithdrawForm.get("amount").setValue(this.avilableLimit, { emitEvent: false });
      }


    })


    this.apiService.getProfile().subscribe((userdata: any) => {

      if (userdata.code === "OK") {
        let profile_detail = userdata.profile;

        this.customer_code = profile_detail.customer_code;
        this.mobile = profile_detail.mobile;
      }
    })
  }





  keyFunc(event) {

    let y = event.target.value

    if (y < this.steps) {
      this.WithdrawForm.get("text_amount").setValue(this.steps, { emitEvent: false });

      this.params.defaultValue = this.steps;
      this.set_span_val = this.steps;
      this.WithdrawForm.get("amount").setValue(this.steps, { emitEvent: false });
    }
    else if (y % this.steps != 0) {
      let checkval = y / this.steps
      let nearestval = parseInt('' + checkval) * this.steps;
      this.set_span_val = nearestval
      this.WithdrawForm.get("text_amount").setValue(nearestval, { emitEvent: false });
      this.params.defaultValue = nearestval;
      this.WithdrawForm.get("amount").setValue(nearestval, { emitEvent: false });

    }
    else {
      /* console.log("else")
       this.params.defaultValue=y;
       this.WithdrawForm.get("amount").setValue(y, {emitEvent: false});
       this.WithdrawForm.get("text_amount").setValue(y, {emitEvent: false});*/

    }

    //console.log("change=",this.set_span_val)
    this.cdRef.detectChanges();
  }
  Goback() {

    let checkforFirstWithdraw = (this.loanDetails.payments).filter(type => type.amount < 0);


    if ((this.loanDetails.limit_product === true) && (this.loanDetails.status === 'limit_approved') || checkforFirstWithdraw.length > 0) {

      this.router.navigate(['/available-limit/']);
    }
    else {
      this.router.navigate(['/credit/']);
    }

  }

  get f() { return this.WithdrawForm.controls }
  openBottomSheet(): void {

    if (this.WithdrawForm.invalid) {
      return;
    }

    if (this.WithdrawForm.valid) {
      let y = this.WithdrawForm.value.text_amount


      if (y < this.steps) {
        this.WithdrawForm.get("text_amount").setValue(this.steps, { emitEvent: false });

        this.params.defaultValue = this.steps;
        this.WithdrawForm.get("amount").setValue(this.steps, { emitEvent: false });
      }
      else if (y % this.steps != 0) {
        let checkval = y / this.steps
        let nearestval = parseInt('' + checkval) * this.steps;

        this.WithdrawForm.get("text_amount").setValue(nearestval, { emitEvent: false });

        this.params.defaultValue = nearestval;
        this.WithdrawForm.get("amount").setValue(nearestval, { emitEvent: false });
      }

      //console.log( this.customer_code,this.mobile)

      let submitted_amount = this.WithdrawForm.value.amount;

      this.apiService.withdrawRequestOtp(this.mobile, submitted_amount).subscribe((data: any) => {

        this.bottomSheet.open(OtppopupComponent, {

          data: { 'mobile': this.mobile, 'customer_code': this.customer_code, 'amount': submitted_amount, 'loan_id': this.loan_id },

        });

      });


    }


  }


  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }



}

