// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
 apiUrl: 'https://sdk-api.arthapp.in/v1/api/',
    //apiUrl: 'https://sdk-api.staging.happy-tech.in/v1/api',
    //apiUrl: 'https://sdk-api.staging.happy-tech.in/v1/api/demopartner',
   // pwaUrl: 'https://sdk-api.staging.happy-tech.in/v1/pwa',
  // pwaUrl: https://sdk-api.arthapp.in/v1/api/sessions/otp
   key_id:"rzp_test_qjjzQpqWbrfDp0",
   secret_key:"l9Wfjce3Soyst9xxnTmXfANy",
   payment_gateway_url:"https://api.razorpay.com/v1/orders"
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.