import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  trans_details: any = [];

  payment_details: any;

  ngOnInit(): void {



    this.apiService.fetchLoanDetails().subscribe((data: any) => {

      let payment = data.loan.payments;

      for (let i = 0; i < payment.length; i++) {
        if (payment[i].amount !== 0) {
          const data = {
            id: payment[i].transaction_id,
            date: new Date(payment[i].transaction_date),
            type: payment[i].amount > 0 ? 'Repay' : 'Withdraw',
            amount: payment[i].amount > 0 ? '₹ ' + payment[i].amount : '-₹ ' + -(payment[i].amount)
            //amount : payment[i].amount > 0 ? payment[i].amount : -(payment[i].amount)
          };

          this.trans_details.push(data);
        }
      }

      this.trans_details = this.trans_details.sort((a, b) => b.date - a.date);
      this.payment_details = this.trans_details;



    })
  }

}
