<div class="container-fluid m-0 p-0">
    <div class="curve-bg">


        <div class="row">
            <div class="col-sm-12">
                <mat-icon class="back-arrow" style="cursor: pointer;" [routerLink]="['/withdraw']">arrow_back</mat-icon>

            </div>
        </div>

        <div class="row text-center">

            <div class="col-sm-4 col-md-4 mx-auto">


                <h2 class="heading-title mb-0">Personal Credit Line</h2>


                <img src="assets/im.png" alt="" class="rounded" />

                <div class="heading mtt-2">
                    <h1 class="m-2 heading-t1"><b>Hi,</b></h1>
                    <h2>{{customer_name}}</h2>

                    <div class="mt-1">
                        <h2 class="mb-1 heading-t2">Your available limit is</h2>
                        <label class="heading-label">₹ {{loanDetails?.principal_repaid | number}}</label>
                    </div>
                </div>

                <div class="custom-m">
                    <h4>Withdraw instantly from your<br> personal credit line</h4>


                    <div class="container mt-4">
                        <input (click)="withdraw()" class="form-control form-btn" type="button" value="Let's get started">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>