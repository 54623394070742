<div class="container-fluid m-0 p-0">

    <div class="curve-bg">
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">

                <!--<mat-icon class="mt-4" style="color: rgb(218, 49, 27); font-size: 5rem;position: relative;
                right: 2rem;">check_circle</mat-icon>-->

                <img src="../../assets/sorry.JPG" class="img-fluid mt-3" />

                <h1 class="heading-title mt-4" style="font-weight: 500;font-size: 2em;">Sorry!</h1>

                <div style="margin-top: 1.9rem; margin-bottom: 7.1rem;">

                    <h3 class="mb-0">You transaction has failed,</h3>
                    
                        <h3 class="mb-0"> Please try again </h3>
                    
                </div>

                <div class="container mt-4">
                    <input [routerLink]="['/withdraw']" class="form-control form-btn" type="button" value="Go back to Withdraw">
                </div>

            </div>
        </div>


    </div>
</div>