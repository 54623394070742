<div class="container-fluid m-0 p-0">

  <div class="curve-bg">
    <div class="row text-center">
      <div class="col-md-4 offset-md-4">
        <div class="mt-3">
          <div class="heading-title" style="font-size:2rem; font-weight: 500;">₹ {{(loanDetails?.principal_repaid) | number}}</div>
          <div class="mt-0">Available Limit</div>
        </div>


      </div>
    </div>

    <div class="container">
      <div class="mt-2">
        <div class="col-md-4 offset-md-4">


          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="p-1 limit-title">Total Limit</div>
              <div class="p-1 limit-title">Interest Applicable</div>
              <div class="p-1 limit-title">Valid Till</div>
            </div>

            <div class="d-flex flex-column">
              <div class="p-1 limit-value">₹ {{loanDetails?.principal | number }}</div>
              <div class="p-1 limit-value">₹ {{interest_amount}} per month</div>
              <div class="p-1 limit-value">{{valid_date}}</div>
            </div>

          </div>

          <div class="container">
            <input [routerLink]="['/withdraw']" class="form-control form-btn mt-3" type="button" value="Withdraw">
          </div>

          <div class="mt-3">

            <div class="due-box">
              <div class="heading text-center p-2" style="font-size: 1.2rem; font-weight: 500;">Clear your dues</div>


              <div class="d-flex flex-row justify-content-around pb-2">
                <div class="d-flex flex-column align-items-center border-right">
                  <div class="p-0 due-title">
                    Principal
                  </div>

                  <div class="pb-3 due-val">
                    ₹ {{(loanDetails?.principal - loanDetails?.principal_repaid) | number}}
                  </div>

                  <div class="p-0 due-title">
                    Interest
                  </div>

                  <div class="p-0 due-val">₹ {{(loanDetails?.interest_charged - loanDetails?.interest_repaid) | number}}</div>
                </div>
                <div class="d-flex flex-column align-items-center" style="border-right: 2px solid #e5dede">
                  <div class="p-0">

                  </div>
                </div>
                <div class="d-flex flex-column align-items-center">

                  <div class="p-0 due-title">
                    Total
                  </div>

                  <div class="p-0 due-val">
                    <!--₹ {{loanDetails?.amount_outstanding  | number}}-->
                    <!-- ₹ {{total_dues_cal  | number}} -->
                    ₹ {{loanDetails?.amount_outstanding | number}}
                  </div>
                  <div class="pt-3">
                  
                    <input (click)="openBottomSheet(loanDetails?.amount_outstanding,loanDetails?.loan_uid,cal_interest_amount)"
                      class="form-control form-btn " type="button" value="Pay Now">
                  </div>
                </div>
              </div>


              <!--<div class="d-flex justify-content-between">
                <div class="p-0">Total Dues</div>
                <div class="p-0"> <input
                    (click)="openBottomSheet(loanDetails?.amount_outstanding,loanDetails?.loan_uid)"
                    class="form-control form-btn " type="button" value="Pay Now"></div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="p-0"><span class="heading" style="font-size: 1.2rem; font-weight: 500;">₹
                    {{loanDetails?.amount_outstanding | number}}</span></div>

              </div>-->


            </div>
          </div>

          <div class="container mt-4">

            <input [routerLink]="['/transaction-history']" class="form-control form-btn-no-bg" type="button"
              value="Transaction History">


          </div>
        </div>
      </div>

    </div>


  </div>


</div>