

<div mat-dialog-content class="text-center">
    <p>
        Something went wrong. Please try again.
       
    </p>
</div>

<div mat-dialog-actions align="center">
    <div *ngIf="data.status==401; else elseBlock">
        <button class="btn btn-primary" style="background-color:#e43779" type="button" (click)="getLogin()">Ok</button>
</div>
<ng-template #elseBlock>
    <button type="button" class="btn btn-primary" style="background-color:#e43779" (click)="closeDialog()">ok</button>
</ng-template>

  </div>
