
      <div class="row justify-content-center text-center mt-4">
            <div class="col-md-12">
                
                <h2 class="heading ">Enter OTP to confirm</h2>
                <label>OTP has been send to your Mobile Number<br>
                {{this.mask_mobile_no}}
              </label>
            </div>
      </div>

     
      <div class="row">
      <div class="">
        <form [formGroup]="PayForm" autocomplete="off">
      <div class="d-flex flex-row mt-4 justify-content-center">
       
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
        
        <!--<div class="p-2"><input type="text" #txt1 maxlength="1" class="form-control form-inputs" (keyup)="move($event, '', txt1, txt2)"></div>
        <div class="p-2"> <input type="text" #txt2 maxlength="1" class="form-control form-inputs" (keyup)="move($event, txt1, txt2, txt3)"></div>
        <div class="p-2"> <input type="text" #txt3 maxlength="1" class="form-control form-inputs" (keyup)="move($event, txt2, txt3, txt4)"></div>
        <div class="p-2"> <input type="text" #txt4 maxlength="1" class="form-control form-inputs" (keyup)="move($event, txt3, txt4, '')"></div>-->
      
    </div>

      <div class="m-4">
        <input (click)="VerifyOTP()" class="form-control form-btn" type="button" value="Confirm">
    </div>
</form>

    <div class="mt-4 text-center">
        <label>Didn't Receive the OTP? </label>
 <div>
          <input (click)="ResendOtp()" class="mt-1" style="color:#e43779;font-weight: 400; text-decoration: none;" type="button" value="Resend">
        </div>
    </div>

      </div>
      </div>

     
    


     