import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.css']
})
export class TransactionDetailsComponent implements OnInit {

  constructor(private apiService: ApiService,private _Activatedroute: ActivatedRoute) { }
  trans_details:any;
  trans_amount:string;
  ngOnInit(): void {

    let trans_id = this._Activatedroute.snapshot.paramMap.get("t_id");
    
    this.apiService.fetchLoanDetails().subscribe((data:any) =>{
      
      if(data.code == "OK"){

        let payment = data.loan.payments;
        this.trans_details= payment.filter(function (p:any) {
        return p.transaction_id == trans_id;

    });

    this.trans_details = this.trans_details[0];
    this.trans_amount =this.trans_details.amount > 0 ? '₹ '+this.trans_details.amount : '-₹ '+ -(this.trans_details.amount)

  }
});



  }

}
