<div class="container-fluid m-0 p-0">

    <div class="curve-bg">
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">

                <img src="../../assets/correct.png" class="img-fluid mt-3" />
                <!--<mat-icon class="mt-4" style="color:  rgb(80 201 201); font-size: 5rem;position: relative;
                right: 2rem;">check_circle</mat-icon>-->

                <h1 class="heading-title mt-3 text-center" style="font-weight: 500;font-size: 2em;">Congratulations!</h1>

                <div class="mt-4">
                    <h3 class="mb-1 heading-t2">You have successfully repaid </h3>
                    <label class="heading heading-label">₹ {{reapy_amount}}</label>
                </div>

                <div style="margin-top: 3.2rem; margin-bottom: 3.2rem;">

                    Your transaction ID <br> XXXXXXXXXXXXXXXXXXXXXXXX
                </div>

                <div class="container mt-4">
                    <input [routerLink]="['/transaction-history']" class="form-control form-btn-no-bg" type="button" value="Transaction History">


                </div>
            </div>
        </div>


    </div>
</div>