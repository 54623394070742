<div class="container-fluid m-0 p-0">
    <div class="curve-bg">
        <div class="row">
            <div class="col-sm-12">
                <mat-icon class="back-arrow" style="cursor: pointer;" (click)="Goback()">arrow_back</mat-icon>

            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">
                <h2 class="heading-title mb-2">Withdraw</h2>

                
                <div style="font-size:16px;" class="pb-2">How much do you want to withdraw?</div>
                <form [formGroup]="WithdrawForm" (ngSubmit)="openBottomSheet()" autocomplete="off" novalidate>

                  <!--<div class="custom-slider pb-3">
                        <ngx-slider [options]="options" ></ngx-slider>
                    </div>-->
                <!--<mat-slider size="large" color="primary" formControlName="amount" [disabled]="disabled" [invert]="invert" [max]="max" [min]="min" [step]="step" [thumbLabel]="thumbLabel" [tickInterval]="getSliderTickInterval()" [(ngModel)]="value" [vertical]="vertical">
                </mat-slider> --> 
                
               
                <!--<p>₹ <span class="input" id="txt" role="textbox" contenteditable>99</span></p>-->
              

               
                
                
                    <div  class="container pb-3">
         <input 
                            #input 
                            type="range" 
                            id="range" 
                           
                            [step]="params.step"
                            [min]="params.min"
                            [max]="params.max"
                            formControlName="amount"
                    >

                </div>
                    <!--  style="accent-color: #4c3196; width: 50%; height: 20px;"-->
                <div class="row justify-content-around" style="line-height: 0;">
                    <div class="col-4">
                        Min {{min}}
                    </div>
                    <div class="col-4">
                        Max {{max}}
                    </div>
                </div>
                <div style="font-size:14px;" class="pb-2">Move the pointer or type the amount.</div>

                <div class="d-flex align-items-center justify-content-center mt-3">
                <div class="amount-box"><span class="rs-symbol">₹</span><span class="input-wrap">
                        <span class="width-machine" aria-hidden="true">{{set_span_val}}</span>
                        <input (mouseout)="keyFunc($event)" (mouseover)="keyFunc($event)"  (change)="keyFunc($event)" formControlName="text_amount" step="{{steps}}" min="{{min}}" max="{{max}}"  class="input-amount" type="number"></span> 
                    </div>
                </div>

                <div class="amount_label mx-auto mt-3">
              
                    

                    <!--[(ngModel)]="inputValue"-->

                     <!--<div class="d-flex align-items-center justify-content-center">
                        <div class="p-2"><span style="color:#e43779 ; font-weight: 500;font-size: 1.8rem;position: relative;
                            left: 1.2rem;"> ₹ </span></div>
                        <div class="p-0"> <input (mouseout)="keyFunc($event)" (change)="keyFunc($event)" type="number" style="position: relative;left: 0.8rem;font-size: 1.8rem;background-color: #e5e0f5; font-weight: 500; color:#e43779; border:none;" class="form-control" formControlName="text_amount" step="{{steps}}"  min="{{min}}" max="{{max}}"></div>

                  
                      </div>-->

                      <!--<div *ngIf="f.text_amount.errors" class="text-danger">
                           
                        <span >
                            Please enter valid mobile number
                        </span>
                    </div>-->
                     
                </div>
                <div class="text-danger">Amount must be multiple of {{steps}}</div>

            
           

                 <!--<div class="d-flex align-items-center justify-content-center mt-3">    
                   
                    <div class="amount_label text-center" style="padding: 0.6rem;">₹ {{sliderControl.value}}</div>
                    
                     <input type="number"  style="font-size: 1.8rem; font-weight: 500; color:#4c3196; border:none;" class="form-control" formControlName="text_amount" min="{{min}}" max="{{max}}">
                    
                </div>-->
                
                <div class="mt-4">
                    <!--<label class="mb-1">Interest {{interest_amount}} @ {{loanDetails?.interest_rate_percent}}</label>-->
                    <label class="mb-1">Interest  ₹ {{interest_amount}} per month </label>
                    <div style="font-weight: 500;">Valid till {{valid_date}}</div>
                    <div class="mt-4"><!--Move the pointer or type the amount <br>-->Processing Fees  {{loanDetails?.processing_fee_percent}}% + GST 18%</div>
                </div>
                <div class="container mt-4">
                    <input [disabled]="!WithdrawForm.valid" class="form-control form-btn" type="submit" value="Withdraw">
                </div>
                </form>
                <!--<div class="heading-title mt-5">View T&C</div>-->

            </div>
        </div>
    </div>
</div>