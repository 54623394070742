<div class="container-fluid m-0 p-0">

  <div class="curve-bg">

    <div class="row">
      <div class="col-sm-12">
        <mat-icon class="back-arrow" style="cursor: pointer;" [routerLink]="['/available-limit']">arrow_back</mat-icon>

      </div>
    </div>

    <div class="row ">
      <div class="col-md-4 offset-md-4">
        <h2 class="heading-title text-center">Transaction History</h2>

        <hr class="new1">

<div class="trans-details mt-1">
        <div class="p-2" *ngFor="let ab of payment_details | groupBy:'date'">
          <div class="mb-2">
            <label class="date-lable">
              <!--4 Jan, 2022--> {{ ab.key| TimeConvert }} </label>

            <div class="trans-box-1 mt-2 p-2 clearfix" *ngFor="let data of ab.value" style="cursor: pointer;"
              [routerLink]="['/transaction-details',data.id]">
              <div class="float-start p-2"> 15.10 <span class="trans-comment-repay">{{data.type}}</span></div>
              <div class="float-end">
                <span [ngClass]="{
                                'succes-trans': data.type === 'Repay',
                                'failed-trans': data.type === 'Withdraw'}">

                  {{data.amount}}

                </span>
                <mat-icon style="position: relative; top: 0.49rem;">keyboard_arrow_right</mat-icon>
              </div>
            </div>

            <!--<div class="trans-box-1 mt-2 p-2 clearfix">
                        <div class="float-start"> 15.10 <span class="trans-comment">Withdraw</span></div>
                        <div class="float-end"><span class="failed-trans">-₹ 100,000 </span>
                            <mat-icon style="position: relative; top: 0.49rem;">keyboard_arrow_right</mat-icon>
                        </div>
                    </div>-->
          </div>
        </div>
        </div>

      </div>
    </div>
  </div>
</div>