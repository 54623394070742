<!--<div class="container-logo" *ngIf="!auth.isAuthenticated()">
    <div class="header">
        <div class="row" style="text-align:center">
            <div class="col-md-4 offset-md-4">
                <img src="assets/logo.png" alt="" height="45px" width="30px" />
               
                <h1 class="mtt-3">Welcome!</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
        </div>
    </div>
</div>-->


<div class="container-fluid">
    <div class="row text-center">
        <div class="col-md-4 mx-auto">
            <h2 class="heading-title">Verify your mobile number</h2>

            <div class="mt-1">OTP has been send to your Mobile Number</div>

            <form [formGroup]="pwaLoginForm" autocomplete="off">
            <div class="row justify-content-center mt-3">

                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>

                <!--<div class="col-3">
                    <input type="text" #txt1 maxlength="1" class="form-control form-inputs" autofocus="" (keyup)="move($event, '', txt1, txt2)">
                </div>
                <div class="col-3">
                    <input type="text" #txt2 maxlength="1" class="form-control form-inputs" (keyup)="move($event, txt1, txt2, txt3)">
                </div>
                <div class="col-3">
                    <input type="text" #txt3 maxlength="1" class="form-control form-inputs" (keyup)="move($event, txt2, txt3, txt4)">
                </div>
                <div class="col-3">
                    <input type="text" #txt4 maxlength="1" class="form-control form-inputs" (keyup)="move($event, txt3, txt4, '')">
                </div>-->


                <div class="text-danger">
                   <!-- <mat-error *ngIf="!otpCtrl.hasError('required') && otpCtrl.hasError('notOfRequiredLength')">
                      OTP should be of size : {{otpMaxLength}}
                    </mat-error> -->
                    <mat-error *ngIf="!otpCtrl.hasError('required') && otpCtrl.hasError('invalidOTP')">
                      Invalid OTP
                    </mat-error>
                    <mat-error *ngIf="otpCtrl.hasError('required')">
                      OTP is <strong>required</strong>
                    </mat-error>
                   
                  </div>

                  <!--<p class="timer" *ngIf="showCountdown">Please wait <span id="timer">(00 :
                    {{countdownCounter | number:'2.0'}})</span></p>-->
                    
            </div>



            <div class="m-3">
                <input (click)="verifyotp()" class="form-control form-btn" type="button" value="Verify">
            </div>
            <label>Didn't Receive the OTP? </label>
            <div>
                <input (click)="ResendOtp()" class="mt-1" style="color:#e43779;font-weight: 500; text-decoration: none;" type="button" value="Resend">
                </div>

</form>
        </div>

    </div>
</div>