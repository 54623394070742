<!--<div class="container-logo">
    <div class="header">
        <img src="assets/logo.png" alt="" height="45px" width="30px" />

        <h1 class="mtt-2">Welcome!</h1>
    </div>
</div>-->
<div class="container-fluid">
    <div class="row text-center">
        <div class="col-md-4 mx-auto">

            <h2 class="heading-title mt-3" style="position: relative; top: 1.2rem;">Login to your account</h2>

            <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off" novalidate>
            <div class="text-center" style="position: relative; top: 2.3rem;">Mobile Number</div>


            <div class="m-5">
                <div>
                    <input class="form-control form-inputs" formControlName="mobile" (keypress)="keyPressNumbers($event)" maxlength="10" type="tel">
                    <div *ngIf="submitted && f.mobile.errors" class="text-danger">
                        <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                        <span *ngIf="f.mobile.errors.pattern">
                            Please enter valid mobile number
                        </span>
                    </div>
                </div>
                <div class="mt-4">
                    <input class="form-control form-btn" type="submit" value="Next">
                </div>
            </div>

        </form>

        </div>
    </div>
</div>