import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { OtpComponent } from './components/otp/otp.component';
import { CreditComponent } from './components/credit/credit.component';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { TransactionDetailsComponent } from './components/transaction-details/transaction-details.component';
import { AvailableLimitComponent } from './components/available-limit/available-limit.component';
import { CongratulationComponent } from './components/congratulation/congratulation.component';
import { SorryComponent } from './components/sorry/sorry.component';
import { DuePaymentComponent } from './components/due-payment/due-payment.component';
import { PayAmountComponent } from './components/pay-amount/pay-amount.component';
import { CongoComponent } from './components/congo/congo.component';
import { OtppopupComponent } from './components/otppopup/otppopup.component';
import { AuthGuardService as AuthGuard  } from './auth/auth-guard.service';
import { UrlSegment, UrlSegmentGroup, Route } from '@angular/router';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { ProcessingfeeComponent } from './components/processingfee/processingfee.component';


const routes: Routes = [

  { path: '', component: LoginComponent },
  { path:'login/:username', component: LoginComponent },
  { path: 'otp/:mobile', component: OtpComponent },
  { path: 'credit', component: CreditComponent, canActivate: [AuthGuard] },
  { path: 'withdraw', component: WithdrawComponent, canActivate: [AuthGuard] },
  { path: 'congo', component: CongoComponent, canActivate: [AuthGuard] },
  { path: 'otppopup', component: OtppopupComponent, canActivate: [AuthGuard]},
  { path: 'transaction-history', component: TransactionHistoryComponent, canActivate: [AuthGuard] },
  { path: 'transaction-details/:t_id', component: TransactionDetailsComponent, canActivate: [AuthGuard] },
  { path: 'available-limit', component: AvailableLimitComponent, canActivate: [AuthGuard] },
  { path: 'congratulation', component: CongratulationComponent, canActivate: [AuthGuard] },
  { path: 'sorry', component: SorryComponent, canActivate: [AuthGuard] },
  { path: 'due-payment', component: DuePaymentComponent, canActivate: [AuthGuard] },
  { path: 'pay-amount', component: PayAmountComponent, canActivate: [AuthGuard] },
  { path: 'processingfee', component: ProcessingfeeComponent, canActivate: [AuthGuard] },
  { path: '**',  component: PagenotfoundComponent },
  { path: '**', redirectTo: 'login' }];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }